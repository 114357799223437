//require('dotenv').config();
import 'ol/ol.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Overlay from 'ol/Overlay';
import XYZ from 'ol/source/XYZ';
import KML from 'ol/format/KML';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Attribution from 'ol/control/Attribution';
import FullScreen from 'ol/control/FullScreen';

import { zip, BlobReader, createReader} from '/js/zip/zip';

   var popupActive = false,
       hoverActive = false;

   /**
    * Elements that make up the popup.
    */
   var popupContainer = document.getElementById('popup');
   var popupContent = document.getElementById('popup-content');
   var popupCloser = document.getElementById('popup-closer');

   var hoverContainer = document.getElementById('hover');
   var hoverContent = document.getElementById('hover-content');

   var helpButton = document.getElementById('help-button');
   var helpContainer = document.getElementById('help-container');
   var helpContent = document.getElementById('help-content');
   var helpCloser = document.getElementById('help-closer');

   /**
    * Create an overlay to anchor the popup to the map.
    */
   var popupOverlay = new Overlay({
     element: popupContainer,
     autoPan: true,
     autoPanAnimation: {
       duration: 250
     }
   });
   var hoverOverlay = new Overlay({
     element: hoverContainer,
     autoPan: false,
     autoPanAnimation: {
       duration: 250
     }
   });


   /**
    * Add a click handler to hide the popup.
    * @return {boolean} Don't follow the href.
    */
   popupCloser.onclick = function() {
     popupOverlay.setPosition(undefined);
     popupCloser.blur();
     popupActive = false;
     return false;
   };

   helpButton.onclick = function() {
      helpContainer.className += " active";
      helpContent.className += " active";
   };

   helpCloser.onclick = function() {
      helpContainer.classList.remove("active");
      helpContent.classList.remove("active");
   };

   var tileLayer = new TileLayer({
      source: new XYZ({
         url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
         attributions: [
            "Daten © <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a>-Mitwirkende,",
            "Kartografie © <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> " +
            "<a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC BY-SA</a>",
         ]
      })
   })
   var trackLayer = new VectorLayer({
     source: new VectorSource({
         format: new KML({
            extractStyles: true,
            defaultStyle:  new Style({
               stroke: new Stroke({
                  width: 4,
                  color: [237, 212, 0, 0.8]
               })
            })
         })
      })
   });

   /* Create the map */
   var map = new Map({
      target: 'map',
      layers: [tileLayer, trackLayer],
      view: new View({
         center: [1149612,6288627],
         projection: 'EPSG:3857',
         zoom: 6
      }),
      overlays: [popupOverlay, hoverOverlay],
      controls: [
         new Attribution({
            collapsible: true,
         }),
         new FullScreen(),
      ],       
      target: 'map',
   });

   map.once('moveend', function(evt){
      var i, ii;
      var features = trackLayer.getSource().getFeatures();
      for (i = 0, ii = features.length; i < ii; ++i) {
         var res = map.getView().getResolution();
         var sf = features[i].getStyle();
         var curStyle = sf(features[i],res);
         var curColor = curStyle[0].getStroke().getColor();
         //console.log (curColor);
         features[i].setStyle (new Style({
             stroke: new Stroke({
               width: 4,
               color: curColor
             })
         }))
      }
   });

   /*---------------------------------------------------*/
   /* KML file 
   /*---------------------------------------------------*/
   var method = "GET";
   var url = "files/Radfernwege_Übersicht.kmz";
   var xhr = new XMLHttpRequest(); 
   if ("withCredentials" in xhr) {
      xhr.open(method, url, true);
   } else if (typeof XDomainRequest != "undefined") {
      xhr = new XDomainRequest();
      xhr.open(method, url);
   } else {
      xhr = null;
   }
   xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
   xhr.onload = function() 
   {
      var blob = xhr.response;//xhr.response is now a blob object
      zip.workerScriptsPath = "js/zip/";
      // use a HttpReader to read the zip 
      zip.createReader(new zip.BlobReader(blob), function(reader) {
         reader.getEntries(function(entries) {
            if (entries.length) {
               entries[0].getData(new zip.TextWriter(), function(text) {
                  reader.close();
                  /* Unzipped data ready */
                  var formatter = new KML({
                     extractStyles: true
                  });
                  var features = formatter.readFeatures(text, {
                     dataProjection: 'EPSG:4326',
                     featureProjection: 'EPSG:3857'
                  });
                  for (var i = 0, ii = features.length; i < ii; ++i) {
                     features[i].setId(i); /* Workaround, OL setzt Feature-Id auf Placemark-Id */
                     var name = features[i].get('name');
                     name = name.replace(' - ','-');
                     features[i].set('name', name);
                     trackLayer.getSource().addFeature(features[i]);
                     var res = map.getView().getResolution();
                     var sf = features[i].getStyle();
                     var curStyle = sf(features[i],res);
                     var curColor = curStyle[0].getStroke().getColor();
                     //console.log (curColor);
                     features[i].setStyle (new Style({
                         stroke: new Stroke({
                           width: 3,
                           color: curColor
                         })
                     }))

                  };
               }, function(current, total) {
               });
            }
         });
      }, function(error) {
        // onerror callback
      })
   }
   xhr.send();

/*
   var fdate ='';
   var http=new XMLHttpRequest();
   http.open('HEAD',url,true);
   http.send(null);
   http.onload = function (evt) {
      if (http.readyState === 4) {
         if (http.status === 200) {
            fdate = http.getResponseHeader('Last-modified')
            var d = new Date(fdate);
            document.getElementById("kml-date").textContent = d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
         }
      }
   };
*/
   // display popup on click and hover
   map.on('singleclick', clickHandler);
   map.on('pointermove', clickHandler);

var featureHovered,
    styleOriginal;

var hoverStyle = new Style({
   stroke: new Stroke({
      width: 7,
      color: [255,0, 255, 0.5]
   })
});
            
function clickHandler(evt) {
  var features = [];

   var hitTol = 8;
   //if (isMobile.matches){
   //   hitTol = 15;
   //}
   map.forEachFeatureAtPixel(evt.pixel,
      function(feature) {
          features.push(feature);
   }, {hitTolerance: hitTol});


   if (evt.type === 'singleclick') {
      if (features.length > 0) {
         var coordinate = evt.coordinate;
         popupContent.innerHTML = '<h1>' + features[0].get('name') + '</h1>' 
                                    + '<p>Artikel im Radreise-Wiki: '
                                    + features[0].get('description')
                                    + '</p>';
         popupOverlay.setPosition(coordinate);
         popupActive = true;
         hoverOverlay.setPosition(undefined);
      } else {
         popupOverlay.setPosition(undefined);
         popupActive = false;
      }
   }else{
      /* Curser at position near track */
      if (! popupActive){
         if (features.length > 0) {
            /* Change track style */
            if (featureHovered != undefined && featureHovered != features[0]){
               featureHovered.setStyle(styleOriginal)
               featureHovered = undefined;
            }    
            if (featureHovered == undefined){
               styleOriginal =  features[0].getStyle();
               features[0].setStyle(hoverStyle);
               featureHovered = features[0];
            }
            /* Display track name */
            var coordinate = evt.coordinate;
            hoverContent.innerHTML = '<p>' + features[0].get('name') + '</p>';
            hoverOverlay.setPosition(coordinate);
         } else {
            hoverOverlay.setPosition(undefined);
            if (featureHovered != undefined){
               featureHovered.setStyle(styleOriginal)
               featureHovered = undefined;
            }
         }
      }
   }
};